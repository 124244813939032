"use strict";

/* ========================================================================
 * DOM-baserad Routing
 * Matchar body class och ersätter - med _
 * ======================================================================== */

(function($) {

    var Site = {
        
        // Alla sidor
        'common': {

            init: function() {
                
                // Mobilemeny
                var scripts = [
                    '/assets/js/components/owl.js',
                    '/assets/js/components/mmenu.js',
                    '/assets/js/components/share.js'
                ];
                
                // Kör igenom alla filer
                for (var i = 0, len = scripts.length; i < len; i++) {

                    jQuery.ajax({
                          url: scripts[i],
                          dataType: "script",
                          cache: false // Ändra till true
                    });
                
                }

            },

            finalize: function() {

                
            }
        
        },

        
        
    };

    // Routing
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Site;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.